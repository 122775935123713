body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.g-card {
  margin: 20px;
}

.g-card-image {
  cursor: pointer;
  border-radius: 10px;
  width: 300px;
  height: 300px;
  box-shadow: 0px 0px 3px 1px #ccc;
  transition: all 0.5s ease-in-out;
}

.g-card-info {
  margin-top: 10px;
  min-height: 100px;
}

.g-card-title {
  font-size: 24px;
  margin: 0px;
}

.g-card-sub-title {
  font-size: 16px;
  margin: 0px;
  width: 300px;
}

.links-cardinfo {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.success-msg {
  color: rgb(24, 228, 38);
  padding: 10px 15px;
}

.err-msg {
  color: rgb(228, 24, 24);
  padding: 10px 15px;
}

.icon {
  color: rgb(99, 4, 4);
}

.icon:hover {
  color: rgb(47, 255, 75);
  transition: 0.5s;
}

.linear-font {
  font-family: "Lexend Mega", sans-serif;
  font-size: 60px;
}

.about-squares {
  width: 100%;
}

.about-text {
  width: 100%;
  padding: 20px;
  font-size: 20px;
  line-height: 32px;
}
.about-square {
  display: grid;
}

.about-img #chef {
  width: 100%;
  margin: 2px;
  border: 1px solid #cea135;
}

/* typewriter */

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid rgb(80, 83, 75); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 1s step-end infinite;
  color: rgb(80, 83, 75);
  font-size: 19px;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(80, 83, 75);
  }
}

@media only screen and (min-width: 768px) {
  .about-squares {
    width: 100%;
    margin: 0 auto;
  }
  .about-text {
    width: 100%;
    padding: 20px;
    font-size: 16px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 1000px) {
  .about-squares {
    width: 100%;
  }
  .about-text {
    width: 50%;
    padding: 20px;
    font-size: 16px;
    line-height: 32px;
    float: left;
  }

  .about-img #chef {
    width: 50%;
    margin: 2px;
    float: right;
    border: 1px solid #cea135;
  }
}
